import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { RoleService } from "../../../services/role.service";
import { DropdownDataUtil } from "../dropdown.data.util";
import { Item } from "../../../pojo/Item";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { Bind } from "../../../decorators/bind.decorator";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-dropdown-multi-contacts")
@Component({
    selector: "s25-ng-dropdown-multi-contacts",
    template: `
        <s25-ng-generic-multiselect-dropdown
            *ngIf="items"
            [onSelect]="onSelect"
            [onDone]="onDone"
            [items]="items"
            [autoOpen]="autoOpen"
            [alwaysOpen]="alwaysOpen"
            [hideSearch]="hideSearch"
            [focusFirst]="focusFirst"
            [searchEnabled]="true"
            [apiBean]="apiBean"
            [serverSide]="serverSide"
            [placeholder]="'Search contacts'"
            [resetSelectedOnCleanup]="resetSelectedOnCleanup"
        >
            <div topContent>
                Contact Role:
                <select [(ngModel)]="chosenRole" (click)="$event.stopPropagation()">
                    <option *ngFor="let role of roles" [ngValue]="role">{{ role.itemName }}</option>
                </select>
            </div>
        </s25-ng-generic-multiselect-dropdown>
    `,
})
export class S25DropdownMultiContactsComponent extends S25DropdownAbstract implements OnInit {
    @Input() type: Item.Ids;

    @Output() done = new EventEmitter<ContactsAndRole>();

    roles: DropDownItem[];
    chosenRole: DropDownItem;

    constructor(private elementRef: ElementRef) {
        super();
    }

    async ngOnInit() {
        this.roles = (await RoleService.getRolesFiltered(this.type)).map((role) => ({
            itemId: role.role_id,
            itemName: role.role_name,
        }));
        this.roles.unshift({ itemId: 0, itemName: "Any Role" });
        this.chosenRole = this.roles[0];
        this.items = [];
        this.serverSide = DropdownDataUtil.getServerSideModel("getContacts", this.elementRef.nativeElement, null);
    }

    @Bind
    onDone({ items }: { items: DropDownItem[] }) {
        const role = { id: Number(this.chosenRole?.itemId), name: this.chosenRole?.itemName };
        const contacts = items.map((item) => ({ id: Number(item.itemId), name: item.itemName, email: item.itemDesc }));
        this.done.emit({ role, contacts });
    }
}

export type ContactsAndRole = {
    role: { id: number; name: string };
    contacts: { id: number; name: string; email: string }[];
};
