//@author: travis
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit } from "@angular/core";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { RateService } from "../../../services/rate.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-dropdown-rate-group")
@Component({
    selector: "s25-ng-dropdown-rate-group",
    template: `
        <ng-container *ngIf="this.items">
            <s25-ng-generic-multiselect-dropdown
                [(chosen)]="this.chosen"
                [hasFav]="false"
                [onSelect]="this.onSelect"
                [items]="this.items"
                [staticItems]="this.staticItems"
                [autoOpen]="this.autoOpen"
                [alwaysOpen]="this.alwaysOpen"
                [hideSearch]="this.hideSearch"
                [focusFirst]="false"
                [searchEnabled]="false"
                [apiBean]="this.apiBean"
                [serverSide]="this.serverSide"
                [placeholder]="'Select Rate Groups'"
                [resetSelectedOnCleanup]="this.resetSelectedOnCleanup"
                (chosenChange)="onChosenChange($event)"
            >
            </s25-ng-generic-multiselect-dropdown>
        </ng-container>
    `,
    styles: `
        ::ng-deep s25-ng-dropdown-rate-group .ngDropdownPaginated a.select2-choice div:first-child {
            display: inline-block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25DropdownMultiRateGroupDropdown extends S25DropdownAbstract implements OnInit {
    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        RateService.getRateGroupItems().then((items) => {
            this.items = items;
            this.onLoad && this.onLoad();
            this.cd.detectChanges();
        });
    }
}
