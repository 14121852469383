//@author travis
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { S25DropdownAbstract } from "../s25.dropdown.abstract";
import { DropDownPaginatedModel } from "../../../pojo/DropDownPaginatedModel";
import { GenericDropdownUtil } from "./generic.dropdown.util";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-generic-multiselect-dropdown")
@Component({
    selector: "s25-ng-generic-multiselect-dropdown",
    template: `
        <ng-container *ngIf="this.items">
            <s25-dropdown-paginated
                [choice]="choice"
                [model]="this.model"
                [(chosen)]="this.chosen"
                (chosenChange)="onChosenChange($event)"
                (isOpenEmit)="onToggle($event)"
            >
                <span options>
                    <ng-content select="[topContent]"></ng-content>
                    <button *ngIf="showDone" class="btn btn-primary ngMultiselectDDDone" (click)="done()">Done</button>
                </span>
            </s25-dropdown-paginated>
        </ng-container>

        <ng-template #choice let-item="item">
            <div>
                <input tabindex="-1" type="checkbox" [checked]="item.isSelected" />
                <span [innerHtml]="item.txt"></span>
                <span *ngIf="item.itemDesc">{{ item.itemDesc }}</span>
            </div>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25GenericMultiselectDropdownComponent extends S25DropdownAbstract implements OnInit {
    @Input() placeholder: string;
    @Input() onDone: Function;
    @Input() items: DropDownItem[];
    @Input() chosen: DropDownItem[];
    @Input() alwaysShowDone?: boolean = true; //May want to hide done button dropdown is closed.

    origItems: DropDownItem[];
    model: DropDownPaginatedModel;
    showDone: boolean;

    constructor(
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        super();
    }

    ngOnInit() {
        this.model = {
            items: GenericDropdownUtil.extractItems(this.items),
            itemNameProp: "txt",
            groupNameProp: "grp",
            placeholder: this.placeholder || "Select items",
            emptyText: "", //no empty text bc some may be loaded on the fly (add param if desired...)
            selectOverride: (item: DropDownItem) => {
                //override select in drop-down-paginated with this (otherwise ddp will select the item, unselect all others, and close itself...)
                item.isSelected = !item.isSelected;
            },
            serverSide: this.serverSide,
            searchEnabled: S25Util.toBool(this.searchEnabled),
            autoOpen: S25Util.toBool(this.autoOpen),
            focusFirst: S25Util.toBool(this.focusFirst),
            alwaysOpen: S25Util.toBool(this.alwaysOpen),
            hideSearch: S25Util.toBool(this.hideSearch),
            resetSelectedOnCleanup: S25Util.toBool(this.resetSelectedOnCleanup),
        };

        this.apiBean = this.apiBean || {};
        S25Util.extend(this.apiBean, {
            getItems: () => {
                return this.model.items || [];
            },
            getFirstElementId: () => {
                return this.model.getFirstElementId();
            }, //Exposed via dropdown-paginated
            getHighlightedItem: () => {
                return this.model.getHighlightedItem();
            }, //Exposed via dropdown-paginated
        });

        this.showDone = this.alwaysShowDone;

        let chosen = this.chosen;
        if (chosen) {
            // set this.chosen to undef if set bc dropdown paginated will think the array of
            // chosen items is itself an item and have an empty item in the selection list
            this.chosen = undefined;
        }

        this.cd.detectChanges();

        if (chosen?.length) {
            this.zone.run(() => {
                this.chosen = chosen; //set chosen back so we can reference it

                // set selected after a zone run so that dropdown paginated picks up the selections
                this.setSelected();
            });
        }
    }

    done() {
        this.chosen = this.model.items.filter((item) => {
            return S25Util.array.isArray(item) ? false : item.isSelected;
        });
        this.onDone && this.onDone({ items: this.chosen });
        this.chosenChange.emit(this.chosen);
    }

    onToggle(isOpen: boolean) {
        this.showDone = this.alwaysShowDone || isOpen;

        // since chosen is not being used by the underlying dropdown paginated,
        // set selected when it opens / closes
        this.setSelected();

        this.cd.detectChanges();
    }

    setSelected() {
        this.model.items.map((item) => {
            item.isSelected = !!this.chosen?.filter((c) => c === item || c.val == item.val || c.itemId == item.itemId)
                .length;
        });
    }
}
